import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        &.type-list {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            padding: 1.5em 2em;
            margin-top: .5em;
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid ${variables.dpc_borderDark};
            width: 100%;

            .wrapper {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .block {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            .block-left {
                width: 40%;

                .title-block {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .image-content {
                        min-width: 4em;
                        min-height: 4em;

                        position: relative;
                        margin-right: 1.5em;
                        margin-left: 1em;

                        .image {
                            position: absolute;
                            width: 100%;
                            height: auto;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                        }

                        &.shape-circle {
                            border-radius: 100%;
                            overflow: hidden;
                        }
                    }

                    .title-content {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 100%;
                        
                        padding: 1em 0;
                        margin-left: 1em;
                        margin-right: 3em;
                        text-transform: uppercase;

                        .element-label {
                            font-weight: 900;
                            font-size: 1em;
                            padding: .25em .5em;
                            background-color: #333333;
                            color: white;
                            display: inline-block;
                            border-radius: .5em;
                            margin-bottom: .5em;
                            margin-right: .5em;

                            &.success {
                                background-color: ${variables.dpc_backgroundGreen};
                            }
                            &.warning {
                                background-color: #fecc37;
                            }
                            &.alert {
                                background-color: #f44336;
                            }
                            &.planned {
                                background: #7070ff;
                            }
                        }

                        .title {
                            font-size: 1.3em;
                            font-weight: 700; 
                            color: ${variables.dpc_fontDark};
                            line-height: 150%;
                        }
                        .subtitle {
                            font-size: .9em;
                            font-weight: 300;
                            color: ${variables.dpc_fontDark};
                        }
                    }
                }
            }

            .block-right {
                width: 60%;
                padding-left: 2em;
                
                .details-block {
                    display: flex;
                    justify-content: flex-end;

                    width: 100%;
                    font-size: 75%;
                    
                    .additionals-block {
                        display: flex;
                        justify-content: space-around;
                        align-items: flex-start; 
                        width: 100%;
                        padding: .5em 0;

                        .additional {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            max-width: 33%;
                            width: 10em;

                            text-align: center;
                            text-transform: uppercase;

                            .additional-label {
                                color: ${variables.dpc_fontDark};
                                margin-bottom: .75em;
                            }

                            .additional-name {
                                color: ${variables.dpc_fontDark};
                                margin-bottom: .75em;
                                font-size: 1.1em;
                                font-weight: 300;
                            }

                            .additional-value {
                                color: ${variables.dpc_fontDark};
                                margin-top: .75em;
                                font-weight: 600;
                                line-height: 1.25em;
                                font-size: 1.09em;

                                &.light {
                                    font-weight: 400;
                                    color: white;   
                                }
                            }

                            .additional-image-wrapper {
                                position: relative;
                                width: 4em;
                                height: 4em;
                                border-radius: 100%;
                                background-color: white;
                                overflow: hidden;

                                .additional-image {
                                    position: absolute;
                                    min-width: 100%;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }
                            }

                            .additional-icon {
                                max-width: 4em;
                            }

                            .additional-label-block {
                                margin: 1em auto;
                                margin-bottom: .5em;
                                min-width: 10em;
                                max-width: max-content;
                                background: #D83143;
                                color: #FFFFFF;
                                padding: .6em;
                                font-size: .85em;

                                &.success {
                                    background-color: #8ED53E;
                                    color: white;
                                }

                                &.warning {
                                    background-color: #fecc37;
                                }

                                &.alert {
                                    background-color: #D83143;
                                }
                                &.planned {
                                    background: #7070ff;
                                }
                            }

                            &:last-of-type {
                                margin-right: 0;
                            }
                        }
                    }
                    .controls-block {
                        margin-left: 5em;
                        margin-right: 1em;

                        &.additionals-justifying-space-evenly-max {
                            min-width: 30%;
                        }
                    }
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &.type-image {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            background-color: transparent !important;
            border-bottom: none !important;
            transition: .3s all;

            margin: 0;
            width: 48%;
            margin-bottom: 2em;
            position: relative;
            cursor: default;

            .main-block {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                background: none;
                overflow: hidden;

                .background-block {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    background-position: center !important;
                    filter: brightness(0.3) contrast(0.7);
                }
                
                .control-content {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;

                    .title-block {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        padding-top: 1em;
                        padding-left: 1em;
                        padding-right: 1em;

                        .title-content {
                            margin-left: 0em;
                            text-transform: uppercase; 

                            .title {
                                font-size: 1.25em;
                                color: black;
                                line-height: 120%;
                                font-weight: 800;
                                word-break: break-word;
                            }
                            .subtitle {
                                display: inline-block;
                                font-size: .8em;
                                color: red;
                                font-weight: 600;
                                margin-bottom: .5em;
                                margin-top: 1em;
                                padding: .25em .5em;
                                word-break: break-word;
                            }
                        }
                    }
                    .details-block {
                        display: flex;
                        align-items: stretch;
                        justify-content: flex-end;
                        flex-direction: column;

                        .additionals-block {
                            display: flex;
                            padding-bottom: 1em;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            .additional {
                                display: flex;
                                justify-content: center;
                                margin-bottom: .5em;
                                font-size: .9em;
                                text-align: center;
                                padding: .4em .5em;
                                border-radius: .5em;    
                                flex-wrap: wrap;

                                &:last-of-type {
                                    margin-bottom: 0;
                                }

                                .additional-name {
                                    color: red;
                                }
                                .additional-value {
                                    margin-left: .25em;
                                    color: red;
                                }
                            }
                        }
                        .controls-block {
                            display: flex;
                            justify-content: center;
                            margin-left: 0em;
                            margin-bottom: 2em;
                        }
                    }
                }
            }

            &:hover {
                transform: scale(1.02);

                .main-block {
                    border: 1px solid #DA3143;
                    box-shadow: rgb(0, 0, 0, 0.2) 0px 2px 10px;

                    .background-block {
                        filter: brightness(0.7) contrast(0.9);
                    }
                }
            }

            &:nth-of-type(odd) {
                background: transparent !important;
            }
        }

        @media (max-width: ${variables.tabletL}) {
            &.type-list {
                width: 100%;
                .wrapper {
                    .block {
                        &.block-left {

                        }
                        &.block-right {
                            .details-block {
                                flex-direction: column;

                                .additionals-block {
                                    justify-content: flex-end;

                                    .additional {
                                        margin-left: 1em;
                                    }
                                }
                                .controls-block {
                                    margin: 1em 0 0 0;
                                }
                            }
                        }
                    }
                }
            }

            &.type-image {
                width: 48%;
            }
        }

        @media (max-width: ${variables.tabletS}) {
            &.type-list {
                .wrapper {
                    flex-direction: column;

                    .block {
                        &.block-left {
                            width: 100%;
                            margin-bottom: 1em;
                            
                            .title-block {
                                justify-content: center;
                                width: 100%;
                                flex-direction: column;

                                .title-content {
                                    margin: 0;
                                    text-align: center;
                                }
                            }
                        }
                        &.block-right {
                            width: 100%;
                            padding-left: 0;

                            .details-block {
                                .additionals-block {
                                    justify-content: space-around;
                                    
                                    .additional {
                                        margin-left: 0em;
                                    }
                                }
                                .controls-block {
                                    margin: 1em auto 0 auto;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: ${variables.mobileL}) {
            &.type-image {
                .main-block {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    background: none;
                    overflow: hidden;
                }
        }

        @media (max-width: ${variables.mobileM}) {
            &.type-list {
                .wrapper {
                    .block {
                        &.block-right {
                            .details-block {
                                .additionals-block {
                                    flex-direction: column;
                                    align-items: center;

                                    .additional {
                                        margin-bottom: 1em;
                                        margin-right: 0;
                                    }
                                }
                                .controls-block {
                                    .admin-paginated-list-element-controls {
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;
                                        
                                        .control {
                                            margin-bottom: .5em;

                                            &:last-of-type {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.type-image {
                width: 100%;
            }
        }
    }
`;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import Controls from 'Components/layout/panel/PaginatedListElementControls';

export default class AdminPaginatedListElementV2 extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        className: PropTypes.string,
        image: PropTypes.shape({
            visible: PropTypes.bool,
            url: PropTypes.string,
            shape: PropTypes.oneOf([
                'square',
                'circle',
            ]),
        }),
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        description: PropTypes.string,
        additionals: PropTypes.arrayOf(
            PropTypes.shape({
                visible: PropTypes.bool,
                type: PropTypes.oneOf([
                    'default',
                    'labels',
                ]),
                style: PropTypes.oneOf([
                    'default',
                    'light',
                ]),
                name: PropTypes.string,
                imageSrc: PropTypes.string,
                value: PropTypes.any,
                labels: PropTypes.arrayOf(
                    PropTypes.shape({
                        isVisible: PropTypes.bool.isRequired,
                        label: PropTypes.string.isRequired,
                        state: PropTypes.oneOf(['', 'warning', 'alert', 'success']),
                    }),
                ),
            })
        ),
        labels: PropTypes.arrayOf(
            PropTypes.shape({
                isVisible: PropTypes.bool.isRequired,
                label: PropTypes.string.isRequired,
                state: PropTypes.oneOf(['', 'warning', 'alert', 'success']),
            }),
        ),
        additionalPosition: PropTypes.oneOf([
            'flex-start', 'space-evenly', 'space-evenly-max',
        ]),
        controls: PropTypes.array,
        stateType: PropTypes.oneOf(['', 'warning', 'alert', 'success']),
        disabled: PropTypes.bool,
        type: PropTypes.oneOf([
            'list', 'image',
        ]),
        controlsType: PropTypes.string,
    };
    static defaultProps = {
        className: '',
        subtitle: null,
        description: null,
        labels: [],
        disabled: false,
        id: null,
        image: null,
        additionals: [{
            style: 'default',
        }],
        additionalPosition: 'flex-start',
        controls: [],
        stateType: '',
        additionalInfos: [],
        type: 'list',
        controlsType: 'default',
    };
    state = {
        activeTooltip: null,
    };

    getAdditional = additional => {
        const { additionals } = this.props;

        switch (additional.type) {
            case 'labels':
                additional.value = additional.value.filter(additionalValue => additionalValue.isVisible !== false);
                if(!additional.value.length) {
                    return null;
                }

                return (
                    <div key={additional.name + additional.value} className="additional">                         
                        <div className={`additional-name ${additional.name}`}> {additional.name} </div>
                        {additional.iconSrc && (<img className="additional-icon" src={additional.iconSrc}/>)}
                        {additional.imageSrc && (
                            <div className="additional-image-wrapper">
                                <img className="additional-image" src={additional.imageSrc}/>
                            </div>
                        )}
                        {additional.value.map((additional, index) => { 
                            return (
                                <div 
                                    key={additional.label + index} 
                                    className={`additional-label-block ${additional.state}`}
                                >
                                    {additional.label}
                                </div>
                            );
                        })
                        }   
                    </div>
                );
            default:
                if(!additional.value) {
                    return null;
                }

                return (
                    <div key={additional.name + additional.value} className="additional">
                        <div className={`additional-name ${additional.name}`}> {additional.name} </div>
                        {additional.iconSrc && (<img className="additional-icon" src={additional.iconSrc}/>)}
                        {additional.imageSrc && (
                            <div className="additional-image-wrapper">
                                <img className="additional-image" src={additional.imageSrc}/>
                            </div>
                        )}
                        <div className={`additional-value ${additional.style}`}> {additional.value}</div>
                    </div>
                );
        }
    }

    getListElementType = (type) => {
        const { id, image, title, subtitle, description, additionals, controls, additionalPosition, controlsType, labels } = this.props;
        const additionalsToDisplay = additionals.filter(additional => additional.visible !== false);
        
        switch(type) {
            case 'list':
                return (
                    <div className="wrapper">
                        <div className="block block-left">
                            <div className="title-block">
                                {image && image.visible ?
                                    <div className={`image-content shape-${image.shape}`} id={`images-content-${id}`}>
                                        <img
                                            className="image"
                                            src={image && image.url ? image.url : require('Theme/images/placeholders/image.jpg')}
                                        />
                                    </div>
                                    : null}
                                <div className="title-content">
                                    {labels
                                        .filter(label => label.isVisible)
                                        .map((label, index) => (
                                        //eslint-disable-next-line react/no-array-index-key
                                            <div key={index} className={`element-label ${label.state}`}>
                                                {label.label}
                                            </div>
                                        ))}
                                    <h3 className="title">{title}</h3>
                                    {Boolean(subtitle) && <p className="subtitle">{subtitle}</p>}
                                    {Boolean(description) && <p className="subtitle">{description}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="block block-right">
                            <div className="details-block">
                                {additionalsToDisplay.length > 0 && (
                                    <div className={`additionals-block justify-${additionalPosition}`}>
                                        {additionalsToDisplay.map(this.getAdditional)}
                                    </div>
                                )}
                                <div className={`controls-block additionals-justifying-${additionalPosition}`}>
                                    <Controls controls={controls} controlsType={controlsType} />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'image':
                return (
                    <div className="main-block">
                        <div className="background-block" style={{ background: `url(${image?.url})` }}/>
                        <div className="control-content">
                            <div className="title-block">
                                <div className="title-content">
                                    <h3 className="title">{title}</h3>
                                    {Boolean(subtitle) && <p className="subtitle">{subtitle}</p>}
                                </div>
                            </div>
                            <div className="details-block">
                                {additionalsToDisplay.length > 0 && (
                                    <div className="additionals-block">
                                        {additionalsToDisplay.map(this.getAdditional)}
                                    </div>
                                )}
                                <div className="controls-block">
                                    <Controls controls={controls} controlsType={controlsType} />
                                </div>
                            </div>
                        </div>
                    </div>
                );
        }
    }

    render() {
        const { className, disabled, stateType, type } = this.props;
        
        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames(
                    'admin-paginated-list-element-v2',
                    stateType,
                    className,
                    { disabled },
                    `type-${type}`
                )}
            >
                {this.getListElementType(type)}
            </StyledComponent>
        );
    }
}
